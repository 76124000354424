import type { LoaderFunctionArgs } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/react";
import type { Locale } from "shared/i18n";
import type { Media } from "@/payload-types";
import { useLoaderData } from "@remix-run/react";
import Blocks from "~/components/Blocks";
import Hero from "~/components/Hero";
import Pad from "~/components/layout/Pad";
import i18next from "~/i18next.server";
import { createTitle } from "~/util/createTitle";
import { nonNullable } from "~/util/nonNullable";
import { getPayload } from "~/util/getPayload.server";

export const loader = async ({
  request,
  params: { page: pageSlug },
}: LoaderFunctionArgs) => {
  const locale = (await i18next.getLocale(request)) as Locale;
  const t = await i18next.getFixedT(locale);
  const payload = await getPayload();
  const [pages, categories] = await Promise.all([
    payload.find({
      collection: "pages",
      where: {
        slug: {
          equals: pageSlug ?? "home",
        },
      },
      populate: {
        pages: {
          layout: true,
          slug: true,
          title: true,
          meta: true,
        },
      },
      depth: 2,
      locale,
    }),
    payload.find({
      collection: "categories",
      locale,
    }),
  ]);

  const page = pages.docs[0];

  if (!page) {
    throw new Response(t("ui.error.pageNotFound"), { status: 404 });
  }

  return {
    page,
    categories,
  };
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  // home page does not have a title
  const title =
    data?.page?.slug === "home"
      ? createTitle()
      : createTitle(data?.page?.title);
  return [
    {
      title,
    },
    {
      name: "description",
      content: data?.page?.meta?.description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: data?.page?.meta?.description,
    },
    {
      property: "og:image",
      content: (data?.page?.meta?.image as Media | undefined)?.url,
    },
  ];
};

export default function Page() {
  const { page, categories } = useLoaderData<typeof loader>();
  return page ? (
    <>
      {page?.showHero && (
        <Hero categories={categories.docs.filter(nonNullable)} />
      )}
      <Blocks blocks={page?.layout} />
      <Pad />
    </>
  ) : null;
}
